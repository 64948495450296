import 'jspdf-autotable'

export const printPDF = content => {
  import('jspdf')
    .then(({ jsPDF }) => {
      const doc = new jsPDF()
      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()
      const center = { align: 'center' }
      if (content.logo && content.id == '1') {
        const img = new Image()
        img.src = `${content.logo}`
        doc.addImage(img.src, 0, 0, width, height, center)
      } else {
        const img = new Image()
        img.src = `${content.logo1}`
        doc.addImage(img.src, 0, 0, width, height, center)
      }
      window.open(doc.output('bloburl'), '_blank')
    })
    .catch(err => console.log(err))
}
